function preventDigits(sender) {
    sender.value = sender.value.replace(/\d/g, "");
}
function validatePhone(phone) {
    phone = phone.replace(/[^0-9]/g, '');
    $('#c_phone').val(phone);
    if(phone == '' || !phone.match(/^[0-9]{10}$/)){
        $('#c_phone').parent().removeClass('done');
        return false;
    }else{
        $('#c_phone').parent().addClass('done');
        return true;
    }
}
function vPhone(phone) {
    phone = phone.replace(/[^0-9]/g, '');
    $('#q_phone').val(phone);
    if(phone == '' || !phone.match(/^[0-9]{10}$/)){
        $('#q_phone').parent().removeClass('done');
        return false;
    }else{
        $('#q_phone').parent().addClass('done');
        return true;
    }
}
$(document).ready(function() {
    $("#firstName").keyup(function(){
        var name = $("#firstName").val().length;
        if($(this).val() !== '' && name >= 2){
            $(this).parent().addClass('done');
        }else{
            $(this).parent().removeClass('done');
        }
    });
    $("#lastName").keyup(function(){
        if($(this).val() !== ''){
            $(this).parent().addClass('done');
        }else{
            $(this).parent().removeClass('done');
        }
    });
    $("#text").keyup(function(){
        if($(this).val() !== ''){
            $(this).parent().addClass('done');
        }else{
            $(this).parent().removeClass('done');
        }
    });
    $("#time").keyup(function(){
        if($(this).val() !== ''){
            $(this).parent().addClass('done');
        }else{
            $(this).parent().removeClass('done');
        }
    });
    $("#desc").keyup(function(){
        if($(this).val() !== ''){
            $(this).parent().addClass('done');
        }else{
            $(this).parent().removeClass('done');
        }
    });
    $("#email").keyup(function(){
        if($(this).val() != '') {
            var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
            if($(this).val() == ''){
                $(this).parent().removeClass('done');
            }else if(pattern.test($(this).val())){
                $(this).parent().addClass('done');
            }
        }
    });
});