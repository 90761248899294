
//windows
$(function () {
    $(".dh").hover(
        function(){
            $(this).children("img").attr("src", "/img/windows/w1Hover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/windows/w1.png");
        }
    );
    $(".bb").hover(
        function(){
            $(this).children("img").attr("src", "/img/windows/w2Hover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/windows/w2.png");
        }
    );
    $(".casement").hover(
        function(){
            $(this).children("img").attr("src", "/img/windows/w3Hover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/windows/w3.png");
        }
    );
    $(".awning").hover(
        function(){
            $(this).children("img").attr("src", "/img/windows/w4Hover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/windows/w4.png");
        }
    );
    $(".sliding").hover(
        function(){
            $(this).children("img").attr("src", "/img/windows/w5Hover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/windows/w5.png");
        }
    );
    $(".garden").hover(
        function(){
            $(this).children("img").attr("src", "/img/windows/w6Hover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/windows/w6.png");
        }
    );
    $(".custom").hover(
        function(){
            $(this).children("img").attr("src", "/img/windows/w7Hover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/windows/w7.png");
        }
    );
});

//doors
$(function () {
    $(".entry").hover(
        function(){
            $(this).children("img").attr("src", "/img/doors/d-entryHover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/doors/d-entry.png");
        }
    );
    $(".french").hover(
        function(){
            $(this).children("img").attr("src", "/img/doors/d-frenchHover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/doors/d-french.png");
        }
    );
    $(".patio").hover(
        function(){
            $(this).children("img").attr("src", "/img/doors/d-patioHover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/doors/d-patio.png");
        }
    );
    $(".storm").hover(
        function(){
            $(this).children("img").attr("src", "/img/doors/d-stormHover.png");
        },
        function(){
            $(this).children("img").attr("src", "/img/doors/d-storm.png");
        }
    );
});