//active menu links
window.addEventListener("load",function(){
    var myLinks = document.querySelectorAll(".nav a");
    for(var i=0; i<myLinks.length; i++) {
        if(location.href==myLinks[i].href){
            myLinks[i].parentNode.classList.add("active");
        }
    }
});
//active scroll menu
$(document).ready(function(){
    $(".nav li").click(function(){
        if(!$(this).hasClass("active")){
            $(this).parents(".nav").find(".active").removeClass("active");
            $(this).addClass("active");
        }
    })
});

//scroll
$(document).ready(function(){
    $(".menu").on("click","a", function () {
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 2000);
    });
    $(".mobMenu").on("click","a", function () {
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 2000);
    });
});

//remove container
$(document).ready(function(){
    var w = document.documentElement.clientWidth;
    
    if(w <= 1199){
        $(".pos").removeClass('container').addClass('container-fluid');
    }
    if (w <= 990){
        $(".bottom").find('.container').removeClass('container').addClass('container-fluid');
        $("footer").find('.container').removeClass('container').addClass('container-fluid');
        $("#remContainer").removeClass('container').addClass('container-fluid');
    }
});

//click gutter circle
$(document).ready(function(){
    $("#circle1").one("click", function(){
        $(".l-hidden").find("#text1").html("Keep Basements Dry").css("visibility", "visible");
        $(".l-hidden").find(".line1").css("visibility", "visible");
    });
    $("#circle2").one("click", function(){
        $(".l-hidden").find("#text2").html("Prevent Rot").css("visibility", "visible");
        $(".l-hidden").find(".line2").css("visibility", "visible");
    });
    $("#circle3").one("click", function(){
        $(".l-hidden").find("#text3").html("Protect home from Leaks").css("visibility", "visible");
        $(".l-hidden").find(".line3").css("visibility", "visible");
    });
    $("#circle4").one("click", function(){
        $(".l-hidden").find("#text4").html("Prevent Erosion").css("visibility", "visible");
        $(".l-hidden").find(".line4").css("visibility", "visible");
    });
    $("#circle5").one("click", function(){
        $(".l-hidden").find("#text5").html("Keep Foundations Stable").css("visibility", "visible");
        $(".l-hidden").find(".line5").css("visibility", "visible");
    });
});

//click and add text

$(document).ready(function(){
    $("#tabs a").click(function(e){
        e.preventDefault();
        $(this).tab('show');
    });
});
